import type { ToolbarProps, TooltipProps } from '@mui/material'
import {
  Stack, Toolbar, useMediaQuery, useTheme,
} from '@mui/material'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import React, { useState } from 'react'

import { RotationAnimation } from '../common/index.ts'
import { useSettings } from '../contexts/index.ts'
import {
  XYOIconBlack, XYOIconColor, XYOTextBlack, XYOTextColor,
} from '../img/index.ts'
import { DropdownSection } from './Dropdown/index.ts'
import { NavbarData } from './NavData/index.ts'

export const ContextToolbar: React.FC<ToolbarProps> = (props) => {
  const theme = useTheme()
  const { darkMode } = useSettings()
  const isNavbarMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const [navigationController, setNavigationController] = useState<[number, boolean]>([0, false])

  return (
    <Toolbar {...props} disableGutters>
      <FlexGrowRow>
        <LinkEx to="/" tabIndex={0}>
          <Stack direction="row" paddingRight={1} spacing={0.5} alignItems="center" justifyContent="center">
            <RotationAnimation rotation={20}>
              <img src={darkMode ? XYOIconColor : XYOIconBlack} height={isNavbarMobile ? 40 : 36} alt="XYO Icon Logo" />
            </RotationAnimation>
            <img src={darkMode ? XYOTextColor : XYOTextBlack} height={isNavbarMobile ? 24 : 24} alt="XYO Text Logo" />
          </Stack>
        </LinkEx>
        <FlexGrowRow>
          {!isNavbarMobile && NavbarData.map((section, index) => (
            <DropdownSection
              dropdownId={index}
              data={section}
              key={index}
              open={navigationController[0] === index ? navigationController[1] : false}
              openController={setNavigationController}
              placement={
                index === 0
                  ? ('bottom-start' as TooltipProps['placement'])
                  : index === NavbarData.length - 1
                    ? ('bottom-end' as TooltipProps['placement'])
                    : ('bottom' as TooltipProps['placement'])
              }
            />
          ))}
        </FlexGrowRow>
      </FlexGrowRow>
    </Toolbar>
  )
}
