import { useTheme } from '@mui/material'
import { useRollbar } from '@rollbar/react'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { ErrorAlert, ThrownErrorBoundary } from '@xyo-network/react-sdk'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { XyoBasePage } from './common/index.ts'
// eslint-disable-next-line import-x/no-internal-modules
import { RootRoutes } from './modules/site/Routes.tsx'

export const AppBodyWithMemoryArchivist: React.FC = () => {
  const theme = useTheme()
  const rollbar = useRollbar()

  return (
    <BrowserRouter>
      <ThrownErrorBoundary
        boundaryName="Application"
        errorComponent={(error, boundaryName) => (
          <XyoBasePage>
            <ErrorAlert errorContext={boundaryName} error={error} />
          </XyoBasePage>
        )}
        rollbar={rollbar}
      >
        <FlexGrowCol
          width="100vw"
          minHeight="100vh"
          justifyContent="flex-start"
          alignContent="stretch"
          bgcolor={theme.palette.background.default}
          color={theme.palette.text.primary}
        >
          <RootRoutes />
        </FlexGrowCol>
      </ThrownErrorBoundary>
    </BrowserRouter>
  )
}
