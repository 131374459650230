import React from 'react'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const SupportFooterLinks: React.FC = (props) => {
  const SupportFooterLinkData: FooterLinkProps[] = [
    { href: 'https://support.xy.company/hc/en-us/categories/360001417734', linkName: 'Help Center' },
    { href: 'https://support.xy.company/hc/en-us/requests/new', linkName: 'Contact Support' },
  ]
  return <FooterLinkLayout links={SupportFooterLinkData} sectionTitle="Support" {...props} />
}
