import React from 'react'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const DeveloperFooterLinks: React.FC = (props) => {
  const DeveloperFooterLinkData: FooterLinkProps[] = [
    { linkName: 'Overview', to: '/developer' },
    { href: 'https://github.com/xyoraclenetwork', linkName: 'Open Source Github' },
    { href: 'https://docs.xyo.network', linkName: 'Documentation' },
    { linkName: 'SDKs', to: '/developer' },
  ]
  return <FooterLinkLayout links={DeveloperFooterLinkData} sectionTitle="Developer" {...props} />
}
