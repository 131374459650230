import {
  Box,
  Fade,
  Slide,
  Typography, useTheme,
} from '@mui/material'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import React, { useState } from 'react'

import { SlideInArrow } from '../../common/index.ts'
import type { NavDropdownCTAProps } from './props/index.ts'

export const DropdownCta: React.FC<NavDropdownCTAProps> = ({
  ctaIcon,
  ctaLink,
  ctaText,
  ctaTitle,

}) => {
  const theme = useTheme()
  const [leftHovered, setLeftHovered] = useState(false)

  return (
    <LinkEx
      to={ctaLink}
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      onMouseEnter={() => setLeftHovered(true)}
      onMouseLeave={() => setLeftHovered(false)}
      placement={`Nav CTA - ${ctaTitle}`}
    >
      <FlexGrowCol
        padding={1}
        width="200px"
        overflow="hidden"
        justifyContent="flex-end"
        alignItems="flex-start"
        textAlign="left"
        bgcolor={theme.palette.primary.main}
      >
        <Fade in={leftHovered} timeout={1000}>
          <Slide in={leftHovered} direction="right" timeout={1000}>
            <Box maxHeight="100%">{ctaIcon}</Box>
          </Slide>
        </Fade>
        <Typography variant="body2" color={theme.palette.primary.contrastText}>
          {ctaTitle}
        </Typography>
        <Typography variant="caption" color={theme.palette.primary.contrastText} display="flex" alignItems="center">
          <span>
            {ctaText}
            <SlideInArrow inTrigger={leftHovered} />
          </span>
        </Typography>
      </FlexGrowCol>
    </LinkEx>
  )
}
