import {
  Divider, Grid, useTheme,
} from '@mui/material'
import { FlexRow } from '@xylabs/react-flexbox'
import type { FooterProps } from '@xyo-network/react-sdk'
import {
  copyrightLinkTitle, Footer, FooterLink,
} from '@xyo-network/react-sdk'
import React from 'react'

import { DeveloperFooterLinks } from './DeveloperFooterLinks.tsx'
import { EcosystemFooterLinks } from './EcosystemFooterLinks.tsx'
import { ResourcesFooterLinks } from './ResourcesFooterLinks.tsx'
import { SocialFooterLinks } from './SocialFooterLinks.tsx'
import { SupportFooterLinks } from './SupportFooterLinks.tsx'
import { TokenFooterLinks } from './TokenFooterLinks.tsx'

const footerLinks = [
  {
    href: 'https://xylabs.com/',
    title: copyrightLinkTitle('XY Labs, Inc.'),
  },
  {
    href: 'https://xyo.network/',
    title: 'XYO Foundation',
  },
  {
    href: 'https://xylabs.com/privacy/',
    title: 'Privacy',
  },
  {
    href: 'https://xylabs.com/terms/',
    title: 'Terms',
  },
  {
    href: 'https://xylabs.com/jobs',
    title: 'Careers',
  },
]

export interface XyoFooterStyledProps extends FooterProps {
  landingPageMode?: boolean
}

export const XyoFooterStyled: React.FC<XyoFooterStyledProps> = ({ landingPageMode, ...props }) => {
  const theme = useTheme()
  return (
    <Footer
      paddingY={4}
      container="xl"
      sx={{ backgroundColor: theme.palette.background.paper }}
      {...props}
    >
      {landingPageMode
        ? null
        : (
            <Grid spacing={2} container paddingBottom={2}>
              <Grid item xs={12} md={2}>
                <SocialFooterLinks />
              </Grid>
              <Grid item xs={6} md={2}>
                <EcosystemFooterLinks />
              </Grid>
              <Grid item xs={6} md={2}>
                <TokenFooterLinks />
              </Grid>
              <Grid item xs={6} md={2}>
                <DeveloperFooterLinks />
              </Grid>
              <Grid item xs={6} md={2}>
                <ResourcesFooterLinks />
              </Grid>
              <Grid item xs={12} md={2}>
                <SupportFooterLinks />
              </Grid>
            </Grid>
          )}
      {landingPageMode
        ? null
        : <Divider flexItem sx={{ marginY: 3 }} />}
      <FlexRow flexWrap="wrap" textTransform="uppercase" {...props}>
        {footerLinks?.map((footerLink, index) => (
          <FooterLink color="inherit" noWrap key={index} paddingX={1} margin={0} {...footerLink}>
            <small>{footerLink.title}</small>
          </FooterLink>
        ))}
      </FlexRow>
    </Footer>
  )
}
