import { Typography, useTheme } from '@mui/material'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import React from 'react'

export interface FooterLinkProps {
  href?: string
  linkName: string
  to?: string
}

export interface FooterLinkLayoutProps extends FlexBoxProps {
  links: FooterLinkProps[]
  sectionTitle: string
}

export const FooterLinkLayout: React.FC<FooterLinkLayoutProps> = ({
  sectionTitle, links, ...props
}) => {
  const theme = useTheme()
  return (
    <FlexCol sx={{ alignItems: { md: 'flex-start', xs: 'center' } }} gap={0.5} {...props}>
      <Typography variant="h6" fontWeight={500} sx={{ color: theme.palette.primary.contrastText }}>
        {sectionTitle}
      </Typography>
      {links.map((link, index) => {
        return (
          <LinkEx
            sx={{ color: theme.palette.primary.contrastText, textAlign: { md: 'left', xs: 'center' } }}
            key={index}
            to={link.to}
            href={link.href}
            target={link.href ?? '_blank'}
            paddingTop={index == 0 ? 1.5 : 0}
          >
            <Typography variant="button">{link.linkName}</Typography>
          </LinkEx>
        )
      })}
    </FlexCol>
  )
}
