import React from 'react'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const TokenFooterLinks: React.FC = (props) => {
  const TokenFooterLinkData: FooterLinkProps[] = [
    { linkName: 'About', to: '/token' },
    { linkName: 'Exchanges', to: '/token/exchange' },
    { linkName: 'Prices', to: '/token/price' },
    { linkName: 'Wallets', to: '/token/wallet' },
    { href: 'https://etherscan.io/address/0x55296f69f40ea6d20e478533c15a6b08b654e758', linkName: 'Contract' },
  ]
  return <FooterLinkLayout links={TokenFooterLinkData} sectionTitle="Token" {...props} />
}
