import {
  CurrencyExchangeRounded,
  X,
} from '@mui/icons-material'
import React from 'react'

import { XYOIconWhite } from '../../img/index.ts'
import { RoadmapShadow } from '../Dropdown/img/index.ts'
import type { DropdownSectionDataProps } from '../Dropdown/index.ts'

export const NavbarData: Array<DropdownSectionDataProps> = [
  {
    cta: {
      ctaIcon: (
        <img
          src={XYOIconWhite}
          style={{
            marginBottom: '20px',
            opacity: 0.2,
            overflow: 'hidden',
            width: '30%',
          }}
        />
      ),
      ctaLink: '/newsletter',
      ctaText: 'Join the XYO Newsletter',
      ctaTitle: 'Learn while we build!',
    },
    colItems: {
      title: '',
      items: [
        {
          desc: 'Understanding the basics of XYO',

          linkText: 'What is XYO?',
          to: '/learn/what-is-xyo',
        },
        {
          desc: 'Decentralized Physical Infrastructure, at your fingertips.',

          linkText: 'DePIN 101',
          to: '/learn/what-is-depin',
        },
        {
          desc: 'The history of the Original DePIN',

          linkText: 'About Us',
          to: '/about',
        },
        {
          desc: 'Our most popular questions, answered.',

          linkText: 'XYO FAQ',
          to: '/faq',
        },
      ],
    },
    name: 'Learn',
    to: '/learn',
    title: 'Start here',
  },
  {
    cta: {
      ctaIcon: (
        <img
          src={XYOIconWhite}
          style={{
            marginBottom: '20px',
            opacity: 0.2,
            overflow: 'hidden',
            width: '50%',
          }}
        />
      ),
      ctaLink: '/ecosystem/earn-as-a-node',
      ctaText: 'Create data with your mobile phone, and earn XYO for participation.',
      ctaTitle: 'Earn XYO with COIN app',
    },
    colItems: {
      title: '',
      items: [
        {
          desc: 'Own your digital identity with xNS',

          linkText: 'Sovereign Domain Names',
          to: '/xns',
        },
        {
          desc: 'Earn XYO Tokens with COIN',

          linkText: 'Smartphone Nodes',
          to: '/ecosystem/earn-as-a-node',
        },
        {
          desc: 'Use dApps and explore XYO with xyOS',

          linkText: 'Operate an XYO Node',
          to: '/xyos',
        },
        {
          desc: "Learn about XYO's Core Modules",

          linkText: 'XYO Ecosystem',
          to: '/ecosystem',
        },
      ],
    },
    name: 'Products',
    to: '/',
    title: 'Your life, sovereign.',
  },
  {
    colItems: {
      title: '',
      items: [
        {
          desc: 'The utility token powering the XYO Ecosystem',

          linkText: 'The XYO Token',
          to: '/token',
        },
        {
          desc: 'View global exchanges trading XYO',

          linkText: 'Exchanges',
          to: '/token/exchange',
        },
        {
          desc: 'View wallets that support XYO',

          linkText: 'Wallets',
          to: '/token/wallet',
        },
      ],
    },
    name: 'Token',
    to: '/token',
    title: 'Powering XYO',
    cta: {
      ctaIcon: (
        <CurrencyExchangeRounded
          fontSize="large"
          style={{
            marginBottom: '20px',
            opacity: 0.2,
            color: '#fff',
          }}
        />
      ),
      ctaLink: '/token/exchange',
      ctaText: 'An easy way to participate in the XYO Ecosystem',
      ctaTitle: 'Buy XYO Token',
    },
  },
  {
    cta: {
      ctaIcon: (
        <X
          fontSize="large"
          style={{
            marginLeft: '-10px',
            marginTop: '-50px',
            opacity: 0.2,
            color: '#fff',
          }}
        />
      ),
      ctaLink: 'https://twitter.com/OfficialXYO',
      ctaText: "Stay up-to-date with XYO's latest announcements on X",
      ctaTitle: 'Follow XYO on X',
    },
    colItems: {
      title: '',
      items: [
        {
          desc: 'Meet the XYO Global community.',

          linkText: 'Social Media',
          to: '/community',
        },
        {
          desc: 'Articles, interviews, & more.',

          linkText: 'News',
          to: '/news',
        },
        {
          desc: 'Announcements & thought pieces.',

          linkText: 'Blog',
          to: '/blog',
        },
        {
          desc: 'Project updates delivered straight to your email.',

          linkText: 'Email Newsletter',
          to: '/newsletter',
        },
        {
          desc: 'Combining efforts to create new technologies.',

          linkText: 'Partners',
          to: '/partners',
        },
      ],
    },
    name: 'Community & News',
    to: '/community',
    title: 'Get Involved',
  },
  {
    cta: {
      ctaIcon: (
        <img
          src={RoadmapShadow}
          style={{
            marginLeft: '-30px',
            marginBottom: '20px',
            opacity: 0.2,
            overflow: 'hidden',
            width: '110%',
          }}
        />
      ),
      ctaLink: '/roadmap/current',
      ctaText: 'Read the 2024 Roadmap to learn more about how to earn rewards for completing XYO Bounties.',
      ctaTitle: 'Coming Soon: Build The Future Initiative',
    },
    colItems: {
      title: '',
      items: [
        {
          desc: 'Understand the technology',

          linkText: 'Developer Overview',
          to: '/developer',
        },
        {
          desc: 'View our open-source code on Github',
          href: 'https://github.com/XYOracleNetwork',
          linkText: 'XYO Github',
        },
        {
          desc: 'A developer tool to create nodes',
          href: 'https://node.xyo.network',

          linkText: 'node.xyo.network',
        },
        {
          desc: 'Use XYO-enabled dApps to participate in XYO',

          linkText: 'Build The Future Initiative',
          to: '/ecosystem/build-the-future',
        },
      ],
    },
    name: 'Developers',
    to: '/developer',
    title: 'Build With Us',
  },
  {
    title: 'The Road Ahead',
    cta: {
      ctaIcon: (
        <img
          src={RoadmapShadow}
          style={{
            fill: 'red', marginLeft: '-30px', opacity: 0.2, overflow: 'hidden', stroke: 'green', width: '90%',
          }}
        />
      ),
      ctaLink: 'https://medium.com/xyonetwork/7083c6ec7c7f',
      ctaText: 'Read the announcement on Medium',
      ctaTitle: '2024 Roadmap Article',
    },
    colItems: {
      title: '',
      items: [
        {

          desc: 'Explore our future',

          linkText: '2024 Roadmap',
          to: '/roadmap',
        },
        {
          desc: 'Look back our 2023 goals',

          linkText: 'Past Roadmap (2023)',
          to: '/roadmap/2023',
        },
      ],
    },
    name: 'Roadmap',
    to: '/roadmap',
  },
]
