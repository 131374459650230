import { useRollbar } from '@rollbar/react'
import { UserEventsProvider } from '@xylabs/react-pixel'
import { assertEx } from '@xylabs/sdk-js'
import React from 'react'

import { AppBodyWithMemoryArchivist } from './AppBodyWithMemoryArchivist.tsx'
import { PixelProvider } from './contexts/index.ts'
import { getXyoUserEvents } from './hooks/index.ts'

export const AppBody: React.FC = () => {
  const userEvents = getXyoUserEvents(import.meta.env.VITE_MIXPANEL_PUBLIC_PROJECT_TOKEN)
  /* const [archivist, setArchivist] = useState<MemoryArchivist>()

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      const newArchivist = await MemoryArchivist.create({ config: { name: 'repositoryArchivist', schema: MemoryArchivist.defaultConfigSchema } })
      await initializeMemoryArchivist(newArchivist)
      if (mounted()) {
        setArchivist(newArchivist)
      }
    },
    [],
  ) */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const windowAny = window as any
  windowAny.rollbar = useRollbar()

  console.warn('userEvents', userEvents)

  assertEx(userEvents, () => 'No user Events!!')

  return (
    <PixelProvider id="xyo.network">
      <UserEventsProvider userEvents={userEvents}>
        {/* {archivist ?
            <MemoryNodeProvider config={{ schema: NodeConfigSchema }} modules={[archivist]}>
              <AppBodyWithMemoryArchivist />
            </MemoryNodeProvider>
          : null} */}
        <AppBodyWithMemoryArchivist />
      </UserEventsProvider>
    </PixelProvider>
  )
}
