import React from 'react'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const EcosystemFooterLinks: React.FC = (props) => {
  const EcosystemFooterLinkData: FooterLinkProps[] = [
    { linkName: 'Overview', to: '/ecosystem' },
    { href: 'https://docs.xyo.network/XYO-White-Paper.pdf', linkName: 'White Paper' },
  ]
  return <FooterLinkLayout links={EcosystemFooterLinkData} sectionTitle="Ecosystem" {...props} />
}
