import { CssBaseline, GlobalStyles } from '@mui/material'
import { Provider as RollbarProvider } from '@rollbar/react'
import { Fbq, Gtag } from '@xylabs/react-pixel'
import { assertEx } from '@xylabs/sdk-js'
import React from 'react'
import { Helmet } from 'react-helmet'
import type { Configuration } from 'rollbar'

import { AppThemeBody } from './AppThemeBody.tsx'
import { SettingsLoader } from './contexts/index.ts'

const rollbarConfig: Configuration = {
  accessToken: assertEx(import.meta.env.VITE_ROLLBAR_TOKEN, () => 'Rollbar token not set'),
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT ?? 'development',
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
}

Fbq.init('1653480428329003')
Gtag.init('G-ZSGG0QNJW7', 'AW-989965544', ['xyo.network'])

export const App: React.FC = () => {
  return (
    <RollbarProvider config={rollbarConfig}>
      <SettingsLoader>
        <Helmet defaultTitle="XYO" titleTemplate="%s" />
        <CssBaseline />
        <GlobalStyles
          styles={{ body: { backgroundColor: '#020223' } }}
        />
        <AppThemeBody />
      </SettingsLoader>
    </RollbarProvider>
  )
}
