import { ArrowForwardRounded } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import type { ReactElement } from 'react'
import React from 'react'

export interface SubLinkSectionProps {
  href?: string
  icon?: ReactElement
  newTab?: string
  text1?: string
  text2?: string
  to?: string
}

export const SubLinkSection: React.FC<SubLinkSectionProps> = ({
  href,
  icon,
  newTab = false,
  text1,
  text2,
  to,
}) => {
  return (
    <FlexGrowRow width="100%" sx={{ flexDirection: { md: 'row', xs: 'column' }, justifyContent: { md: 'flex-start', xs: 'center' } }}>
      {icon
        ? (
            <span>
              {icon}
&nbsp;
            </span>
          )
        : null}
      <Typography>
        {text1}
&nbsp;
      </Typography>
      <LinkEx href={href} to={to} style={{ textDecoration: 'underline', textUnderlineOffset: '5px' }} target={newTab ? '_blank' : undefined} color="inherit">
        <Stack flexDirection="row" gap={0.5} alignItems="center">
          {text2}
          <ArrowForwardRounded />
        </Stack>
      </LinkEx>
    </FlexGrowRow>
  )
}
