import { useTheme } from '@mui/material'
import type { ButtonExProps } from '@xylabs/react-button'
import { ButtonEx } from '@xylabs/react-button'
import React from 'react'

export interface ButtonSectionProps extends ButtonExProps {
  buttonText?: string
  href?: string
  to?: string
}

export const ButtonSection: React.FC<ButtonSectionProps> = ({
  href, to, buttonText, ...props
}) => {
  const theme = useTheme()
  return (
    <ButtonEx
      marginTop={1}
      marginBottom={1}
      target={href ?? '_blank'}
      to={to}
      href={href}
      color="primary"
      variant="contained"
      paddingX={3}
      sx={{
        display: href || to ? 'flex' : 'none',
        marginLeft: { sm: theme.spacing(0), xs: theme.spacing(2) },
        marginRight: { sm: theme.spacing(1), xs: theme.spacing(2) },
      }}
      {...props}
    >
      {buttonText}
    </ButtonEx>
  )
}
