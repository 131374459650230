import React from 'react'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const ResourcesFooterLinks: React.FC = (props) => {
  const ResourcesFooterLinkData: FooterLinkProps[] = [
    { linkName: 'Resources', to: '/resources' },
    { linkName: 'Papers', to: '/papers' },
    { linkName: 'Partners', to: '/partners' },
    { linkName: 'Brand Guidelines & Logos', to: '/brand' },
  ]
  return <FooterLinkLayout links={ResourcesFooterLinkData} sectionTitle="Resources" {...props} />
}
